import { Action, Actions, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { LoginSite, Logout, ResetSettingVideoResolution, UpdateSettingVideoResolution, UpdateToken, UpdateUserId, UpdateVideoResolution } from "./app.action";
import { STATE_TOKEN, StateModel, initialState } from "./app.state.model";


@State(
  {
    name: STATE_TOKEN,
    defaults: initialState,
  }
)
@Injectable({ providedIn: 'root' })
export class AppState {

  constructor(private actions$: Actions) { }

  @Action(UpdateToken, { cancelUncompleted: true })
  updateToken(ctx: StateContext<StateModel>, action: UpdateToken) {

    ctx.patchState({
      token: action.token,
    });
  }

  @Action(UpdateUserId, { cancelUncompleted: true })
  UpdateUserId(ctx: StateContext<StateModel>, action: UpdateUserId) {
    ctx.patchState({
      userId: action.userId,
    });
  }

  @Action(LoginSite, { cancelUncompleted: true })
  LoginSite(ctx: StateContext<StateModel>, action: LoginSite) {
    ctx.patchState({
      site: action.site,
    });
  }
  @Action(Logout, { cancelUncompleted: true })
  Logout(ctx: StateContext<StateModel>, action: Logout) {
    ctx.setState(initialState);
  }

  @Action(UpdateVideoResolution, { cancelUncompleted: true })
  UpdateVideoResolution(ctx: StateContext<StateModel>, action: UpdateVideoResolution) {
    ctx.patchState({
      videoResolution: action.videoResolution
    });
  }
  @Action(UpdateVideoResolution, { cancelUncompleted: true })
  UpdateSettingVideoResolution(ctx: StateContext<StateModel>, action: UpdateSettingVideoResolution) {
    ctx.patchState({
      settingVideoResolution: action.videoResolution
    });
  }
  @Action(ResetSettingVideoResolution, { cancelUncompleted: true })
  ResetSettingVideoResolution(ctx: StateContext<StateModel>, action: ResetSettingVideoResolution) {
    ctx.patchState({
      settingVideoResolution: null,
    });
  }

  @Selector()
  static getSettingVideoResolution(state: StateModel) {
    return state.settingVideoResolution || [];
  }
}
